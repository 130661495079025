import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";

interface CardProps {
  title: string;
  description: string;
  image: string;
}

const HoverCard: React.FC<CardProps> = ({ title, description, image }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [titleWidth, setTitleWidth] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, [title]);

  return (
    <div
      className="relative w-full max-w-xs aspect-[3/4] rounded-lg overflow-hidden shadow-lg transition-all duration-500"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Background image with blur effect on hover */}
      <img
        className={`absolute inset-0 w-full h-full object-cover transition-all duration-500 ${
          isHovered ? "blur-sm" : ""
        }`}
        src={image}
        alt={title}
      />

      {/* Title and Green Line */}
      <motion.div
        className="absolute left-4 text-white w-fit transition-all duration-500"
        initial={{ bottom: "3%" }}
        animate={isHovered ? { bottom: "36%" } : { bottom: "3%" }}
      >
        <div
          ref={titleRef}
          className="text-3xl font-bold"
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}
        >
          {title}
        </div>

        {/* Green Line appearing from outside the left border */}
        {isHovered && (
          <motion.div
            className="h-1 bg-green-500 mt-1 absolute"
            initial={{ x: "-100%", width: "0" }} // Start fully to the left of the card
            animate={{
              x: 0,
              width: titleWidth,
              transition: { duration: 0.5, ease: "easeInOut" },
            }}
            exit={{
              x: "-100%",
              width: 0,
              transition: { duration: 0.5, ease: "easeInOut" },
            }}
          />
        )}
      </motion.div>

      {/* Description Text (Under Title, Sliding Up on Hover) */}
      {isHovered && (
        <motion.div
          initial={{ bottom: "-20%", opacity: 0 }}
          animate={{ bottom: "15%", opacity: 1 }}
          exit={{ bottom: "-20%", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut", delay: 0.8 }}
          className="absolute left-4 text-white"
        >
          <p className="text-lg">{description}</p>
        </motion.div>
      )}
    </div>
  );
};

export default HoverCard;
