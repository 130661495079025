"use client";

import { useEffect, useState, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function TextFlip() {
  const words = useMemo(
    () => [
      { text: "fantastic", color: "text-red-500" },
      { text: "love", color: "text-green-500" },
      { text: "fire", color: "text-orange-500" },
      { text: "awesome", color: "text-purple-500" },
      { text: "fantastic", color: "text-blue-500" },
    ],
    []
  );

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((current) => (current + 1) % words.length);
    }, 1300); // Cycle every 1.3 seconds

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <span className="inline-block" style={{ width: "80px", textAlign: "left" }}> {/* Fixed width for no shifting */}
      <AnimatePresence mode="wait">
        <motion.span
          key={`words_${index}`}
          initial={{ opacity: 0, y: 20, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -20, scale: 0.95 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className={`inline-block ${words[index].color} transition-colors duration-500`}
          style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", width: "100%", textAlign: "left" }}
        >
          {words[index].text}
        </motion.span>
      </AnimatePresence>
    </span>
  );
}
